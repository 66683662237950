import { useMemo } from 'react';
import { Heading } from '@mediahuis/chameleon-react';
import { Ad, LeaderboardAd } from '@hubcms/feature-ads';
import { Container } from '@hubcms/ui-container';
import { StoryHeader } from '@hubcms/ui-story';
import { useAdPositions } from '@hubcms/utils-ads';
import { warn } from '@hubcms/utils-monitoring';
import { usePager } from '@hubcms/utils-pagination';
import { getThemeDataAttributes } from '@hubcms/utils-theme';
import { SectionData, TagPageTemplateData } from '@hubcms/domain-section';
import { deduplicateObjectsByKey, partitionArray } from '@hubcms/utils-browser';
import { tagPageHeadingProps } from '@hubcms/brand';
import { TeaserPropsOptions } from '@hubcms/domain-teaser';
import cx from 'classnames';

import { InfiniteScrollArticleList } from '../InfiniteScrollArticleList';

import styles from './tagpage.module.scss';
import TagPageTemplate from './TagPageTemplate';

type TagPageProps = {
  sectionData: SectionData;
  templateData: TagPageTemplateData;
  teaserPropsOptions: TeaserPropsOptions;
};

const TAG_INTRO_TEASER_AMOUNT = 3;

const TagPage = ({ sectionData, templateData, teaserPropsOptions }: TagPageProps) => {
  const { pagedArticlesData } = sectionData;

  const {
    items: articles,
    isLoading,
    loadPage,
    hasMore,
  } = usePager({
    pageSize: pagedArticlesData.pageSize,
    initialItems: pagedArticlesData.initialItems,
    initialAmount: pagedArticlesData.initialAmount + TAG_INTRO_TEASER_AMOUNT,
    initialHasMore: pagedArticlesData.initialHasMore,
    secondPageSearchParams: pagedArticlesData.secondPageSearchParams,
    getPageDataFromResponse: pagedArticlesData.getPageDataFromResponse,
    getNextPageSearchParams: pagedArticlesData.getNextPageSearchParams,
  });

  // Filter out duplications
  const deduplicatedArticles = useMemo(() => deduplicateObjectsByKey(articles, 'id'), [articles]);

  // Slice some articles for the template
  const [templateArticles, infiniteScrollArticles] = useMemo(() => {
    const [b2bTagIntros, nonB2bTagIntros] = partitionArray(
      deduplicatedArticles,
      article => article.contentType === 'tagIntroB2B',
    );

    if (templateData.bodyElements.length > 0) {
      return [
        nonB2bTagIntros.slice(0, TAG_INTRO_TEASER_AMOUNT),
        b2bTagIntros.concat(nonB2bTagIntros.slice(TAG_INTRO_TEASER_AMOUNT)),
      ];
    }

    return [[], [...b2bTagIntros, ...nonB2bTagIntros]];
  }, [deduplicatedArticles, templateData]);

  const { getAdFormatForPage } = useAdPositions(pagedArticlesData.adPageSkip, pagedArticlesData.adFormats);

  if (!pagedArticlesData.initialAmount) {
    warn('tagpage.pagination.webInitialAmount not set. will cause pagination issues.');
  }

  if (!pagedArticlesData.pageSize) {
    warn('tagpage size not specified; using default of 10.');
  }

  if (sectionData.noContentMessage) {
    return (
      <Container>
        <Heading className={styles.emptyHeading} size="xl" level={4}>
          {sectionData.noContentMessage}
        </Heading>
      </Container>
    );
  }

  const dividerAdFormat = sectionData.templateDividerAdFormat;
  const titleComponent = templateData.bodyElements.length ? (
    <div className={styles.mainContent}>
      <TagPageTemplate templateData={templateData} tagBlockArticles={templateArticles} />
      {dividerAdFormat && <Ad adFormat={dividerAdFormat} adSlot="b" className={styles.dividerAd} />}
    </div>
  ) : (
    <div className={styles.mainContent}>
      <StoryHeader className={styles.header} headingData={{ text: sectionData.title, annotations: [] }}>
        <StoryHeader.Headline {...tagPageHeadingProps} className={styles.heading} />
      </StoryHeader>
    </div>
  );

  const getAdClassName = (index: number) => {
    return index + 1 === pagedArticlesData.pageSize
      ? cx({
          [styles.headingSideAdContainer]: templateData.storyHeaderData,
        })
      : '';
  };

  return (
    <>
      <LeaderboardAd className={styles.topMargin} />
      <Container fullWidthSm fullWidthMd className={styles.topMargin} {...getThemeDataAttributes(sectionData.theme)}>
        <InfiniteScrollArticleList
          getAdClassName={getAdClassName}
          prependChildren={titleComponent}
          articles={infiniteScrollArticles}
          teaserHasDateTime={pagedArticlesData.teaserHasDateTime}
          pageSize={pagedArticlesData.pageSize}
          initialPageSize={pagedArticlesData.initialAmount}
          isLoading={isLoading}
          hasMore={hasMore}
          getAdFormatForPage={getAdFormatForPage}
          loadMore={loadPage}
          className={styles.content}
          teaserPropsOptions={teaserPropsOptions}
        />
      </Container>
    </>
  );
};
export default TagPage;
